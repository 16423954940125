import axios from 'axios'
import cookies from 'js-cookie'
import { Message } from 'element-ui'

//创建一个axios对象
const service = axios.create({
    baseURL: '/api',
    timeout: 1000 * 5,
})


//请求拦截
service.interceptors.request.use(
    (config) => {
        // console.log('请求拦截赋值：', config)
        let token = cookies.get('token')
        if (token) config.headers['Authorization'] = token
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

//响应拦截
service.interceptors.response.use(
    (res) => {
        let resData = res.data
        if (res.data.code === 200) {
            return resData
        } else {
            Message.error(resData.msg)
        }
    },
    (error) => {
        console.log('网络请求响应异常结果：', error)
        return Promise.reject(error)
    }
)

export default service