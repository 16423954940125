import VueRouter from 'vue-router'
import { isLogin } from "@/utils/userInfo"

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/home',
            alias: ['/'],
            name: 'home',
            component: () => import('@/views/reception/home/index.vue'),
            meta: { title: '首页' }
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/reception/login/index.vue'),
            meta: { title: '论坛登录' }
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/reception/register/index.vue'),
            meta: { title: '论坛注册' }
        },
        {
            path: '/postDetails',
            name: 'postDetails',
            component: () => import('@/views/reception/details/index.vue'),
            meta: { isAuth: true, title: '详情页' }
        },
        {
            path: '/postVideoDetails',
            name: 'postVideoDetails',
            component: () => import('@/views/reception/details/video.vue'),
            meta: { isAuth: true, title: '详情页' }
        },
        {
            path: '/postPublic',
            name: 'postPublic',
            component: () => import('@/views/reception/post-public/index.vue'),
            meta: { isAuth: true, title: '帖子发布' }
        },
        {
            path: '/personalCenter',
            name: 'personalCenter',
            component: () => import('@/views/reception/personal-center/index.vue'),
            meta: { isAuth: true, title: '个人中心' },
            children: [{
                path: '/personalCenterInfo',
                name: 'personalCenterInfo',
                component: () => import('@/views/reception/personal-center/info/index.vue'),
                meta: { isAuth: true, title: '个人中心' }
            }, {
                path: '/personalCenterWallet',
                name: 'personalCenterWallet',
                component: () => import('@/views/reception/personal-center/wallet/index.vue'),
                meta: { isAuth: true, title: '我的钱包' }
            }
            ]
        },
        {
            path: '/postCenter',
            name: 'postCenter',
            component: () => import('@/views/reception/post-center/index.vue'),
            meta: { isAuth: true, title: '贴子中心' },
            children: [{
                path: '/postCenterCollect',
                name: 'postCenterCollect',
                component: () => import('@/views/reception/post-center/collect/index.vue'),
                meta: { isAuth: true, title: '收藏' }
            },{
                path: '/postCenterDraft',
                name: 'postCenterDraft',
                component: () => import('@/views/reception/post-center/draft/index.vue'),
                meta: { isAuth: true, title: '草稿箱' }
            },{
                path: '/postCenterLike',
                name: 'postCenterLike',
                component: () => import('@/views/reception/post-center/like/index.vue'),
                meta: { isAuth: true, title: '点赞' }
            },{
                path: '/postCenterPlus',
                name: 'postCenterPlus',
                component: () => import('@/views/reception/post-center/my-plus/index.vue'),
                meta: { isAuth: true, title: '我发布的' }
            },{
                path: '/postCenterReply',
                name: 'postCenterReply',
                component: () => import('@/views/reception/post-center/reply/index.vue'),
                meta: { isAuth: true, title: '我评论的' }
            },{
                path: '/postCenterVisitsLog',
                name: 'postCenterVisitsLog',
                component: () => import('@/views/reception/post-center/visits_log/index.vue'),
                meta: { isAuth: true, title: '足迹' }
            }
            ]
        },
        {
            path: '/noticeView',
            name: 'noticeView',
            component: () => import('@/views/reception/notice/index.vue'),
            meta: { isAuth: true, title: '消息通知' }
        },
        {
            path: '/menuView',
            name: 'menuView',
            component: () => import('@/views/backstage/menu/index.vue'),
            meta: { isAuth: true, isSysAuth: true, title: '菜单管理' }
        },
        {
            path: '/userView',
            name: 'userView',
            component: () => import('@/views/backstage/user/index.vue'),
            meta: { isAuth: true, isSysAuth: true, title: '用户管理' }
        },
        {
            path: '/CarouselMessageView',
            name: 'CarouselMessageView',
            component: () => import('@/views/backstage/carousel/index.vue'),
            meta: { isAuth: true, isSysAuth: true, title: '轮播图管理' }
        },
    ]
})

//全局前置路由守卫
router.beforeEach((to, from, next) => {
    /* console.log(' 是否登录 ： ', isLogin())
    if (to.meta.isAuth && !isLogin()) {
        router.push({name:'login'})
        return;
    }*/
    document.title = to.meta.title || '论坛'
    next()
})


export default router