import cookies from 'js-cookie'
import store from '@/store'
import axios from '@/utils/request'
import { USER_NAME_PREFIX, USER_INFO_PREFIX } from "@/utils/cacheUtil"

//验证当前是否有登录用户
export const checkLoginStatus = async () => {
    //验证Cookie中是否有Token值
    let token = cookies.get('token')
    if (!token) {
        return false
    }

    //验证Token是否过期
    await axios.get('/getUserInfo').then(res => {
        store.dispatch('updateUseInfo', res.data)
        sessionStorage.setItem(USER_INFO_PREFIX, JSON.stringify(res.data))
    })
}

export const isLogin = () => {
    let userInfo = sessionStorage.getItem(USER_INFO_PREFIX)
    if (userInfo) userInfo = JSON.parse(userInfo)
    return userInfo.nickName ? true : false
}

export const getUserName = async (id) => {
    if (!id) return ''
    let object = sessionStorage.getItem(USER_NAME_PREFIX + id)
    if (object) {
        return object
    }
    let res = await axios.get('/user/getUserName/' + id)
    if (res && res.data) {
        sessionStorage.setItem(USER_NAME_PREFIX + id, res.data)
        return res.data
    }
    return ''
}

// 用户密码重置
export function updateUserPwd(params) {
    return axios({
        url: '/updatePwd',
        method: 'get',
        params: params
    })
}


//更新用户信息
export function updateUserInfo(userInfo) {
    return axios({
        url: '/user/update',
        method: 'post',
        data: userInfo
    })
}