import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Cookies from 'js-cookie'
import enLocale from './en'
import zhLocale from './zh'
import bmLocale from './bm'
import zhtwLocale from './zh-tw'

Vue.use(VueI18n)//全局挂载


const messages = {
  en: {
    ...enLocale
  },
  zh: {
    ...zhLocale
  },
  bm:{
    ...bmLocale
  },
  zhtw:{
    ...zhtwLocale
  }
}


const i18n = new VueI18n({
  locale: Cookies.get('language') || 'zh',
  messages
})

export default i18n
