import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

//用于响应组件中动作
const actions = {
    updateUseInfo(context, userInfo) {
        context.commit('updateUseInfo', userInfo)
    }
}

//用户操作数据
const mutations = {
    updateUseInfo(state, userInfo) {
        state.userInfo = userInfo
    }
}

//存储数据
const state = {
    systemName: '论坛',
    userInfo: {},
    isLogin: false
}

export default new Vuex.Store({
    actions,
    mutations,
    state
})